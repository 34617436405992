@import 'mixin';

.banner{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    .conteiner{
        height: 674px;
        padding:2.5rem 0;
        @media screen and (max-width:1400px) {
            height: 286px; 
        }
        @media screen and (max-width:991px) {
            a{
                display: none;
            }
        }
        .btn_main{
            height: 60px;
            font-size: 20px;
        }
    }
}

.catalog{
    .h2{
        @include h2;
        margin-bottom: 2.5rem;
        margin-top: 7rem;
        @media screen and (max-width:991px) {
            margin-bottom: 2rem;
            margin-top: 3rem;
        }
        @media screen and (max-width:425px) {
            margin-bottom: 2rem;
            margin-top: 1.5rem;
        }
    }
    .body{
        column-gap: 2rem;
        display: grid;
        grid-template-columns: repeat(3, calc(34% - 2rem));
        justify-content: center;
        place-items: center;
        row-gap: 5rem;
        width: 100%;
        @media screen and (max-width:991px) {
            grid-template-columns: repeat(2, calc(51% - 1rem));     
            column-gap: 1rem;     
            row-gap: 1rem; 
        }
        .item{
            width: 100%;
            
            .img{
                position: relative;
                overflow: hidden;
                display: flex;
                justify-content: center;
                height: 230px;
                width: 100%;
                transition: .5s ease;
                @media screen and (max-width:991px) {
                    height: 120px;
                }
                &::after{
                    content: '';
                    display: flex;
                    height: 12px;
                    width: 100%;
                    background: $yellow;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: .5s ease;
                }
                
                picture{
                    height: 100%;
                    width: 100%;
                    transition: .5s ease;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: scale(1);
                    display: flex;
                    justify-content: center;
                    img{
                        width: 100%;
                    }
                }
            }
            .name{
                font-family: Montserrat;
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: $black;
                margin-top: 1rem;
                height: 60px;
                @media screen and (max-width:991px) {
                    font-size: 18px;                    
                }
                @media screen and (max-width:375px) {
                    font-size: 14px;                    
                }
            }

            @media(hover:hover){
                &:hover{
                    .img{
                        img{
                            transform: scale(1.3);
                            transition: .5s ease;
                        }
                        &::after{
                            width: 0;
                            transition: .5s ease;
                        }
                    }
                }
            }
            @media(hover:none){
                &:active{
                    .img{
                        img{
                            transform: scale(1.3);
                            transition: .5s ease;
                        }
                        &::after{
                            width: 0;
                            transition: .5s ease;
                        }
                    }
                }
            }
        }
    }
}

.constructor{
    padding-top: 11rem;
    padding-bottom: 18.7rem;
    @media screen and (max-width:991px) {
        padding-bottom: 5rem;
        padding-top: 4rem;
    }
    .title{
        @include h2;
        color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width:991px) {
            flex-direction: column;
            align-items: flex-start;
        }
        ul{
            @include h_list;
            margin-bottom: 0;
            li{
                a{
                    @include p;
                    color:$black;
                    margin: 0 12px;
                    position: relative;
                    @media screen and (max-width:991px) {
                        margin: 0 5px;
                    }
                    &::after{
                        content: '';
                        display: flex;
                        height: 1px;
                        width: 0;
                        background: $black;
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        transition: .5s ease;
                    }
                    @media(hover:hover){
                        &:hover{
                            &::after{
                                width: 100%;
                                transition: .5s ease;
                                text-decoration: none;
                            }
                        }
                    }
                    @media(hover:none){
                        &:active{
                            &::after{
                                width: 100%;
                                transition: .5s ease;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .body{
        column-gap: 1px;
        display: grid;
        grid-template-columns: repeat(5, calc(20% - 1px));
        justify-content: center;
        place-items: center;
        width: 100%;
        margin-top: 5.3rem;
        @media screen and (max-width:1400px) {
            grid-template-columns: repeat(4, calc(20% - 1px));
            row-gap: 1px; 
        }
        @media screen and (max-width:1200px) {
            grid-template-columns: repeat(3, calc(20% - 1px));
        }
        @media screen and (max-width:991px) {
            grid-template-columns: repeat(2, calc(50% - 1px));
            margin-top: 2.5rem;
        }
        .item{
            background: $black;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 239px;
            width: 100%;
            @media screen and (max-width:991px) {
                width: 100%;
                svg{
                    width: 60px;
                }
            }
            .text{
                @include h5;
                color: $white;
                text-align: center;
                margin-top: 1rem;
                padding: 0 2.4rem;
                @media screen and (max-width:991px) {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }
            }
        }
    }
}

.proizvodstvo{
    background: $darkGray;
    padding: 7.25rem 0;
    @media screen and (max-width:991px) {
        padding: 3.5rem 0;
    }
    .conteiner{
        justify-content: space-between;
        flex-direction: row;
        @media screen and (max-width:991px) {
            flex-direction: column;
        }
    }
    .right{
        margin-left: 9.1rem;
        @media screen and (max-width:1400px) {
            margin-left: 3rem;
        }
        @media screen and (max-width:991px) {
            margin-left: 0;
            order: 1;
        }
    }
    .left{
        @media screen and (max-width:991px) {
            order: 2;
        }
        img{
            border-bottom: 12px solid $yellow;
            width: 100%;
        }
    }
    
    .title{
        @include h2;
        color: $white;
        font-weight: 500;
    }
    .desc{
        @include h6;
        @include regular;
        color: $white;
        margin-top: 1.5rem;
        font-weight: 400;
        line-height: 26px; 
        opacity: 0.8;
        @media screen and (max-width:991px) {
            margin-bottom: 2rem;
        }
        p{
            margin: 0; 
            color: $white;
            @include h6;
            @include regular;
            font-size: 20px;
        }
    }
}

.kachestvo{
    padding: 5rem 0;
    @media screen and (max-width:991px) {
        padding-bottom: 1.5rem;
    }
    .title{
        @include h2;
        margin-bottom: 2.5rem;
    }
    .body{
        column-gap: 18px;
        display: grid;
        grid-template-columns: repeat(3, 346px);
        justify-content: center;
        place-items: center;
        width: 100%;
        @media screen and (max-width:1200px) {
            grid-template-columns: repeat(2, 346px);
            row-gap: 2rem;
        }
        @media screen and (max-width:768px) {
            grid-template-columns: repeat(1, 100%);
            row-gap: 2rem;
        }
        .certificat{
            text-align: center;
        }
        img{
            width: 100%;
        }
        .desc{
            @include h7;
            text-align: center;
            text-transform: unset;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            line-height: 19.2px;
            padding: 0 2rem;
            @media screen and (max-width:991px) {
                margin-top: 1rem;
            }
            strong{
                font-size: 14px;
                margin-bottom: .5rem;
            }
        }
    }
}

.brenduvannya{
    background: $yellow;
    @media screen and (max-width:991px) {
        padding: 3.4rem 1rem;
    }
    .conteiner{
        flex-direction: row;
        @media screen and (max-width:991px) {
            flex-direction:column;
        }

    }
    .left{
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .right{
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:991px) {
            margin-top: 2.5rem;
        }
        img{
            @media screen and (max-width:1400px) {
                width: 100%;
            }
        }
    }
    .title{
        @include h2;
    }
    p{
        @include p;
        color: $black;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 3rem;
        margin-right: 5.6rem;
        @media screen and (max-width:1400) {
            margin-right: 3rem;
        }
        @media screen and (max-width:1400) {
            margin-right: 1.5rem;
        }
        @media screen and (max-width:991px) {
            font-size: 18px;
            line-height: 130%;
            font-weight: 500;
            margin-top: 2rem;
            margin-bottom: 1rem;
            margin-right: 0;
        }
    }
    .desc{
        @include p;
        margin-top: 2rem;
        margin-bottom: 2.9rem;
        padding-right: 6.1rem;
        @media screen and (max-width:991px) {
            padding-right: 0;
        }
    }
    .btn_main{ 
        width: fit-content;
        padding: 1.3rem 4.5rem;
        @media screen and (max-width:991px) {
            font-size: 14px;   
            width: 100%; 
            padding: 0;        
        }
    }
}

.partners{
    background: $black;
    padding-top: 5.6rem;
    padding-bottom: 4.75rem;
    @media screen and (max-width:991px) {
        padding-top: 3.1rem;
        padding-bottom: 3,6rem;
    }
    .conteiner{
        flex-direction: column;
    }
    .h2{
        width: 100%;
        @include h2;
        color:$white;
        border-bottom: 1px solid $white;
        padding-bottom: 2.5rem;
        @media screen and (max-width:991px) {
            padding-bottom: 2rem;
        }
    }
    .body{
        column-gap: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 62%);
        justify-content: right;
        @media screen and (max-width:991px) {
            grid-template-columns: repeat(auto-fill, 100%); 
        }
        .right{
            column-gap: 5px;
            display: grid;
            grid-template-columns: repeat(auto-fill, 24%);
            justify-content: right;
            place-items: end;
            @media screen and (max-width:991px) {
                grid-template-columns: repeat(auto-fill, 32%);         
            }
            img{
                width: 210px;
                padding: 5px;
                @media screen and (max-width:1400px) {
                    width: 100%;
                }
                @media screen and (max-width:991px) {
                    width: 100%;
                    padding: 10px 15px;
                }
            }
        }
    }
}